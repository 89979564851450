@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

.buttonPayment {
  width: 100%;
  background-color: #fff;
  height: 36px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  margin-bottom: 20px;
  padding: 10px, 8px, 10px, 6px;
  cursor: pointer;
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-family: 'Inter', sans-serif;
}

.buttonPayment:hover {
  color: #1D4E92;
}

.containerPaymentCard {
  height: 78vh;
  /* position: fixed; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
}

.containerPaymentCard::-webkit-scrollbar {
  display: none;
}

.containerFilter {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 3px;
}

.buttonPdf {
  width: 150px;
  background-color: #1D4E92;
  color: #fff;
  border-radius: 20px;
  padding: 10px;
  border: none;
  cursor: pointer;
  font-size: 10px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  margin-right: 10px;
}

.buttonInvoice {
  width: 150px;
  background-color: #0E1F41;
  color: #fff;
  border-radius: 20px;
  padding: 10px;
  border: none;
  cursor: pointer;
  font-size: 10px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
}

.inputFilter {
  width: 150px;
  height: 40px;
  /* background-color: #1D4E92;
  color: #fff;
  border-radius: 4px;
  padding: 10px 8px 10px 6px; */
  border: none;
  cursor: pointer;
  /* font-size: 16px;
  font-weight: 400;
  font-family: 'Inter', sans-serif; */
}

.inputFilter:hover {
  border: none;
}

.inputTextField {
  background-color: #FFFFFF;
  max-width: 163px;
  /* max-height: 51px; */
  border: none;
}

.inputDate {
  height: 20px;
  background-color: #FFFFFF;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  padding: 10px 8px 10px 6px;
  border: none;
  cursor: pointer;
  /* margin-left: 10px; */
  font-size: 16px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
}

.inputProperty {
  width: 163px;
  max-height: 55px;
  background-color: #FFFFFF;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  padding: 10px 8px 10px 6px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;

}

.divider {
  border: 1px solid #e5ebf2;
}

.containerAllCRedCard {
  height: 75vh;
  overflow: scroll;
}

.containerAllCRedCard::-webkit-scrollbar {
  display: none;
}

.containerCredCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.containerCredCard .buttonMakeMain {
  background-color: transparent;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  border-radius: 30px;
  border: 2px solid #1D4E92;
  padding: 4px 16px 4px 16px;
  gap: 10px;
  color: #1D4E92;
  cursor: pointer;
}

.containerCredCard .buttonMain {
  background-color: #1D4E92;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  border-radius: 30px;
  padding: 4px 16px 4px 16px;
  gap: 10px;
  color: #FFFFFF;
  border: none;
}

.containerCredCard .containerInfoCards {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #1D4E92;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
}

.buttonCredCard {
  background-color: #7CC8E4;
  width: 100%;
  height: 40px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 40px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;
}

.resetFilter {
  height: 20px;
  background-color: #FFFFFF;
  border: 1px solid #d0d0d0;
  align-self: flex-end;
  margin-bottom: 2px;
  border-radius: 3px;
}