@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

.containerTopBar {
  background-color: #FDFDFD;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.containerRightTopBar {
  display: flex;
  align-items: center;
}

.containerRightTopBar .containerText {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  margin: 0 25px;
}

.containerRightTopBar .containerText .name {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #1D4E92;
}

.containerRightTopBar .containerText .position {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #A9B2C2;
 }
