.nameReview {
  color: #0E1F41;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  line-height: 24px;
}

.textReview {
  color: #1D4E92;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 8px;
}

.buttonSendReview {
  width: 96%;
  background-color: #1D4E92;
  padding: 10px 8px 10px 6px;
  color: #FFFFFF;
  border: none;
  margin: auto;
  cursor: pointer;
}

.outlined-basic {
  width: 100px;
}