@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

.containerCardCalendar {
  height: 138px;
  background-color: #FDFDFD;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
}

.containerCardCalendar img {
  width: 136px;
  height: 118px;
  border-radius: 3px;
  /* margin: 10px; */
}

.containerCardCalendar .containerLeft {
  display: flex;
  align-items: center;
}

.containerCardCalendar .containerLeft .containerInfoReview {
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 20px;
}

.containerCardCalendar .containerLeft .containerInfoReview .nameReview {
  color: #0E1F41;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  line-height: 18px;
  text-overflow: "...";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.containerCardCalendar .containerLeft .containerInfoReview .textReview {
  color: #1D4E92;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 0px;
}

.containerCardCalendar .containerLeft .containerInfoReview .space {
  margin-bottom: 5px;
}

.containerCardCalendar .containerLeft .containerInfoReview .done {
  font-weight: 700;
}

.deleteCleaningButton {
  border:0 none;
  cursor: pointer;
  background-color: transparent;
}
