@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

.buttonNewProperty {
  width: 100%;
  background-color: #1D4E92;
  height: 36px;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  margin-top: 3%;
  padding: 10px, 8px, 10px, 6px;
  cursor: pointer;
  text-transform: uppercase;
}

.buttonDisable {
  background-color:#bfc0c2;
  width: 100%;
  height: 36px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  margin-top: 50px;
  padding: 10px, 8px, 10px, 6px;
  border: none;
}

.subtitlePageProperty {
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 20px;
  display: flex;
}

.listOfTasks {
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  text-decoration: underline;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.containerTextList {
  display: flex;
  flex-wrap: wrap;
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}

.titleList {
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  margin-top: 10px;
  margin-left: 15px;
  text-transform: uppercase;
}

.containerAddTak {
  background-color: #CDD3DC;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.containerAddTak .button {
  background-color: #7CC8E4;
  width: 100%;
  height: 40px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.containerAddTak .button span {
  margin-left: 8px;
  color: #FFFFFF;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}

.containerAddTak .button img {
  margin-left: 8px;
}

.containerAddTak .input {
  height: 40px;
  border: none;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  /* padding-left: 20px; */
  color: rgba(0, 0, 0, 0.87);
}


.containerNewList {
  max-height: 23vh;
  overflow: auto;
  margin-bottom: 20px;
}

.containerNewList div {
  width: 100%;
  border-radius: 5px;
  height: 44px;
  background-color: #fff;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.containerNewList div button{
  border: none;
  background-color: transparent;
  margin-right: 20px;
  cursor: pointer;
}

.containerNewList div span {
  margin-left: 20px;
  overflow: auto;
  text-overflow: "...";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
}

.button {
  background-color: #7CC8E4;
  width: 40%;
  height: 40px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.button label {
  margin-left: 8px;
  color: #FFFFFF;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}

.button img {
  margin-left: 8px;
}

input[type="file"] { 
  display: none; 
}

.label-bordered {
  border:  none;
}

.containerAllCardsPhotos {
  display: flex;
  max-width: 54vw;
  overflow: auto;
}

.containerAllDone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 70vh;
}

.containerAllDone img {
  /* width: 100%; */
}

.containerAllDone .title {
  color: #0E1F41;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  margin-top: 20px;
}

.containerAllDone .subtitle {
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #000000;
  margin-top: 20px;
}

.containerAllDone button {
  background-color: #86C71C;
  max-width: 199px;
  height: 41px;
  border: none;
  cursor: pointer;
  border-radius: 50px;
  margin-top: 20px;
}

.control-label:hover {
  cursor: pointer;
}