@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

.containerStep7 {
  height: 80vh;
  /* position: fixed; */
  overflow: scroll;
}

.containerStep7::-webkit-scrollbar {
  display: none;
}

.conatainerStep7Buttons {
  display: flex;
  max-width: 726px;
  margin-bottom: 20px;
}

.conatainerStep7Buttons .buttonReviews {
  flex: 1;
  background-color: #FDFDFD;
  height: 48px;
  border: none;
  font-size: 10px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
}

.conatainerStep7Buttons .buttonReviewsSelected {
  border-bottom: 2px solid #0E1F41;
}

.conatainerStep7Buttons .containerAllCards {
  height: 55vh;
  /* position: fixed; */
  overflow: auto;
  display: flex;
  max-width: 721px;
  flex-direction: column;
}

.conatainerStep7Buttons .containerAllCards::-webkit-scrollbar {
  display: none;
}

.conatainerStep7Input {
  display: flex;
  justify-content: space-between;
}

.conatainerStep7Input .input{
  width: 43%;
}

.conatainerStep7Buttons .inputReset {
  width: 20%;
  background-color: #7cc8e4;
  border: none;
  cursor: pointer;
}

.linkContainer {
  max-height: 100px;
  overflow: auto;
  margin-bottom: 20px;
}

.linkContainer div{
  background-color: #0E1F41;
  color: #FDFDFD;
  /* width: 30%; */
  border-radius: 5px;
  height: 44px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.linkContainer span {
  text-overflow: "...";
  margin-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
}

.linkContainer button {
  border: none;
  background-color: transparent;
  margin-right: 20px;
  cursor: pointer;
}

.subtitleIcall {
  color: #0E1F41;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  margin-left: 14px;
  margin-bottom: 20px;
}

.goBackButton {
  background-color: #0E1F41;
  width: 44px;
  height: 44px;
  border-radius: 50px;
  font-size: 26px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  color: #FFFFFF;
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
  cursor: pointer;
  padding-left: 0px;
  padding-right: 2px;
  padding-top: 0px;
  padding-bottom: 3px;
}


.conatainerStep7Input .button {
  height: 48px;
  border: none;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  padding-left: 20px;
  border-radius: 0;
  background-color: #FDFDFD;
  color: rgba(0, 0, 0, 0.87);
  width: 48%;
}

/* .buttonDate {
  background-color: #FFFFFF;
  width: 48%;
} */

.containerCoupon {
  display: flex;
  justify-content: end;
  margin-bottom: 15px;
  align-items: flex-end;
  margin-top: 20px;
}

.titleCost {
  width: 85px;
  font-weight: bold;
}

.costValue {
  width: 68px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #0E1F41;
  font-weight: bold;
}

.dividerCoupon {
  background-color: #0E1F41;
  width: 2px;
  height: 57px;
}

.containerSendCoupon{
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
  gap: 8px;
}

.containerSendCoupon input {
  height: 30px;
  border: none;
  border-radius: 5px;
  margin-right: 8px;
}

.containerSendCoupon button {
  height: 30px;
  width: 80px;
  border: none;
  background-color: #0E1F41;
  color: white;
  border-radius: 5px;
}