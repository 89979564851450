@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

.containerCalendarEdit {
  display: flex;
  flex-wrap: wrap;
  /* width: 646px; */
  flex-direction: row;
}

.containerCalendarEdit .textDate {
  color: rgba(14, 31, 65, 1);
  font-size: 24px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  margin-bottom: 15px;
  margin-top: 0;
}

.containerCalendarEdit .random {
  background-color: rgba(205, 211, 220, 1);
}

.containerCalendarEdit .react-datepicker__day--selected {
  color: #fff;
  background-color: rgba(134, 199, 28, 1);
  border-radius: 0;
}

.containerCalendarEdit .react-datepicker__day--selected:hover {
  background-color: rgba(134, 199, 28, 1);
  color: #fff;
}

.scheduleButton {
  background-color: #0E1F41;
  width: 200px;
  height: 44px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  color: #FFFFFF;
  margin-top: 10px;
  border: none;
  cursor: pointer;
}

.containerCalendarEdit .react-datepicker {
  padding: 37px;
  width: 646px; /*646px*/
  border: none;
  border-radius: 8px;
  margin-top: 10px;
}

/* react-datepicker__day react-datepicker__day--016 react-datepicker__day--selected */