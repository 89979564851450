@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

.containeModal {
  position: relative;
  overflow: auto;
  height: 587px;
}

.containeModal::-webkit-scrollbar {
  display:none;
}


.containerCardDetailSchedule {
  border-radius: 10px;
}

.containerCardDetailSchedule .sutitleCard{
  color: #0E1F41;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 25px;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.containerCardDetailSchedule .sutitleCard img {
  margin-right: 5px;
}

.containerCardDetailSchedule .divider {
  border: 10px solid #FDFDFD;
}

.containerTitleDetailSchu {
  width: 80%;
}

.imageModal img {
  max-height: 95%;
}

body .extended {
  position: absolute;
  right: 10px;
  top: 20px;
  background-color: green;
  padding: 5px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #FDFDFD;
}