@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

.containerSchu {
  height: 80vh;
  overflow: scroll;
}

.containerSchu::-webkit-scrollbar {
  display: none;
}

.containerCalendar {
  display: flex;
  flex-wrap: wrap;
}

.containerCardsCalendar {
  margin-left: 25px;
  margin-top: 0;
}

.containerCardsCalendar .cards {
  max-height: 70vh;
  width: 30vw;
  overflow: scroll;
}

.backgroudNoDate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: "none";
  margin-top: 50px;
}

.backgroudNoDate img {
  width: 200px;
}

.backgroudNoDate div {
  margin-top: 20px;
  color: rgba(29, 78, 146, 1);
  font-size: 16px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
}

.containerCardsCalendar .cards::-webkit-scrollbar {
  display: none;
}

.textDate {
  color: rgba(14, 31, 65, 1);
  font-size: 24px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  margin-bottom: 15px;
  margin-top: 0;
}

.random {
  background-color: rgba(205, 211, 220, 1);
}

.react-datepicker__day--selected {
  color: #fff;
  background-color: rgba(134, 199, 28, 1);
  border-radius: 0;
}

.react-datepicker__day--selected:hover {
  background-color: rgba(134, 199, 28, 1);
  color: #fff;
}

.react-datepicker {
  padding: 37px;
  width: 37vw;
  border: none;
  border-radius: 8px;
}

.react-datepicker__month-container {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  color: rgba(14, 31, 65, 1);
}

.react-datepicker__navigation,
.react-datepicker__current-month {
  margin-bottom: 30px;
}

.react-datepicker__navigation {
  padding: 38px;
  padding-top: 60px;
}

.react-datepicker__navigation-icon {
  color: red;
  background-color: transparent;
}
.react-datepicker__navigation-icon--previous {
  color: red;
  background-color: transparent;
}

.react-datepicker__header {
  background-color: transparent;
  border: none;
}

.react-datepicker__day-names,
.react-datepicker__week {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.react-datepicker__day-name {
  font-size: 10px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color: rgba(169, 178, 194, 1);
  padding: 0px 15px;
}

.react-datepicker__current-month {
  font-size: 24px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  color: rgba(14, 31, 65, 1);
  width: 50px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.cardMarginRigth {
  margin-right: 20px;
}

.cardWidth {
  width: 15vw;
}

.react-datepicker__day {
  padding: 0px 12px;
  margin: 8px 0px;
  /* color: #0E1F41; */
}

.containerFIlterCalendar {
  display: flex;
  margin-bottom: 10px;
}

.containerFIlterCalendar .conatinerRegion {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.containerFIlterCalendar .conatinerProperty {
  display: flex;
  flex-direction: column;
}

.titleFilter {
  font-size: 15px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
}

.onOnboradingButton {
  background-color: #0e1f41;
  color: #fff;
  width: 60px;
  height: 60px;
  font-size: 30px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  border-radius: 50%;
  position: absolute;
  right: 30px;
  cursor: pointer;
}

/* react-datepicker__day react-datepicker__day--016 react-datepicker__day--selected */

@media screen and (max-width: 1274px) {
  .containerCalendar {
    max-height: 82vh;
    overflow: scroll;
  }
  
  .containerCalendar::-webkit-scrollbar {
    display: none;
  }

  .containerCardsCalendar {
    margin-left: 0px;
    margin-top: 25px;
  }
  .containerCardsCalendar .react-datepicker {
    width: 50vw;
  }
  
  .containerCardsCalendar .react-datepicker__month-container {
    width: 50vw;
  }

  .containerCardsCalendar .cards {
    width: 57vw;
    display: flex;
  }
}