.containerOnboard {
  height: 100vh;

}

.backgroudCollO {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100VW;
}
.cardContainer {
  width: 38vw;
  height: 79vh;
  background: linear-gradient(180deg, #AFE2FF 6.75%, #FFFFFF 100.5%);
  box-shadow: 2.8px 5.6px 14px rgba(0, 0, 0, 0.25);
  border-radius: 28px;
  position: relative;
}

.img3, .img2, .img1 {
  position: absolute;
}

.img1 {
  bottom: 0;
  left: 0;
}

.img2 {
  top: 0;
  right: 0;
}

.img3 {
  bottom: 70px;
  right: 70px;
}

.buttonOnborad {
  position: absolute;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.buttonOnborad .buttonNext {
  display: flex;
  flex-direction: row;
  justify-content: center;
    align-items: center;
    padding: 14px;
    gap: 14px;
    width: 10vw;
    height: 5vh;
    background: #426AFF;
    border-radius: 14px;
    border: none;
    font-family: 'Chivo';
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    line-height: 26px;
    color: #FFFFFF;
    cursor: pointer;
  }
  
.buttonPrevious {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px;
  gap: 14px;
  width: 10vw;
  height: 5vh;
  background: #FFFFFF;
  border: 1.4px solid #426AFF;
  border-radius: 14px;
  font-family: 'Chivo';
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 26px;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
}