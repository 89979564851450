.containerCardNotifications {
  height: 70vh;
  overflow: scroll;
}

.containerCardNotifications::-webkit-scrollbar {
  display:none;
}

.containerInfoCard {
  background-color: #fff;
  max-width: 587px;
  height: 56px;
  padding: 0px 10px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.containerInfoCard button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.containerInfoCard .left {
  display: flex;
}

.containerInfoCard .left div {
  margin-left: 10px;
}