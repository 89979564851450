.containerCardScren {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  height: 100%;
}

.titleTextOnboard {
  font-family: 'Chivo';
  font-style: normal;
  font-weight: 900;
  line-height: 67px;
  text-align: center;
  color: rgba(12, 20, 92, 0.96);
  font-size: 3vw;
}

.titleTextOnboard2 {
  font-family: 'Chivo';
  font-style: normal;
  font-weight: 900;
  font-size: 2.2vw;
  line-height: 48px;
  text-align: center;
  color: rgba(12, 20, 92, 0.96);
  width: 70%;
}

.suntitleTextOnborad {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 1.4vw;
  line-height: 28px;
  text-align: center;
  color: #00186D;
  width: 65%;
  margin-top: 4vh;
}

.containerCardScren .img {
  width: 90%;
}

.textScren6 {
  font-family: 'Roboto';
  font-style: normal;
  font-size: 1.3vw;
  line-height: 28px;
  color: #00186D;
}