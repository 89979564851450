@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

.titleCard {
  color: #0E1F41;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  text-overflow: "...";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* width: 80%; */
}

.addressCard {
  color: #0E1F41;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
}

.seletor {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.titlePage {
  color: #0E1F41;
  font-size: 24px;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  margin-bottom: 0;
  margin-top: 0;
}

.subtitlePage {
  color: #0E1F41;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  margin-bottom: 15px;
  margin-top: 8px;
}

.container {
  position: relative;
}

.DuplicateIcon {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 0px;
  background-color: transparent;
  border: none;
  cursor: pointer;   
}
