@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

.containerEditProperty {
  height: 80vh;
  overflow: scroll;
}

.containerEditProperty::-webkit-scrollbar {
  display: none;
}

.conatainerButtons {
  display: flex;
  max-width: 721px;
  margin-top: 20px;
}

.buttonNotSelected {
  flex: 1;
  background-color: #FDFDFD;
  height: 48px;
  border: none;
  font-size: 10px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  color: #0E1F41;
  text-transform: uppercase;
}

.buttonSelected {
  border-bottom: 2px solid #0E1F41;
}

.containerAllCards {
  height: 55vh;
  /* position: fixed; */
  overflow: scroll;
  display: flex;
  max-width: 721px;
  flex-direction: column;
}

.containerAllCards::-webkit-scrollbar {
  display: none;
}

.containerBackgroundProperty {
  max-width: 721px;
  height: 262px;
  background-repeat: no-repeat;
  background-size: cover ;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
}

.containerBackgroundProperty .title {
  font-size: 24px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  color: rgba(14,31,65);
  margin-top: 20px;
  text-overflow: "...";
  margin-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow:
    -2px -0px 5px rgb(253, 253, 253, 1),
    -2px 2px 5px rgb(253, 253, 253, 1),
    5px -3px 5px rgb(253, 253, 253, 1),
    5px 3px 5px rgb(253, 253, 253, 1);
}

.containerBackgroundProperty .adress {
  font-size: 12px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  color: rgba(14,31,65);
  margin-left: 20px;
  margin-top: 10px;
  text-shadow:
    -2px -0px 5px rgb(253, 253, 253, 1),
    -2px 2px 5px rgb(253, 253, 253, 1),
    5px -3px 5px rgb(253, 253, 253, 1),
    5px 3px 5px rgb(253, 253, 253, 1);
}

.containerBackgroundProperty button {
  background-color: #0E1F41;
  width: 172px;
  height: 44px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  color: #FFFFFF;
  margin-left: 20px;
  margin-bottom: 20px;
  border: none;
  cursor: pointer;
}

.buttonSaveProperty {
  width: 100%;
  background-color: #1D4E92;
  height: 36px;
  color: #fff;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  margin-top: 3%;
  padding: 10px, 8px, 10px, 6px;
  cursor: pointer;
}
