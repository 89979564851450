@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

.containerCardReview {
  /* flex: 1; */
  height: 138px;
  background-color: #FDFDFD;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
}

.containerCardReview img {
  width: 136px;
  height: 118px;
  border-radius: 3px;
  /* margin: 10px; */
  margin-right: 12px;
}

.containerCardReview .containerLeft {
  display: flex;
  align-items: center;
}

.containerCardReview .containerLeft .containerInfoReview {
  margin-left: 5px;
}

.containerCardReview .containerLeft .containerInfoReview .nameReview {
  color: #0E1F41;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
}

.containerCardReview .status {
  display: flex;
}

.containerCardReview .containerLeft .containerInfoReview .adressReview {
  color: #0E1F41;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  margin-bottom: 6px;
}

.containerCardReview .containerLeft .containerInfoReview .textReview {
  color: #1D4E92;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  margin: 0;
}

.containerCardReview .containerLeft .containerInfoReview .done {
  font-weight: 700;
}
