@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');


.containerBackgroundLogin {
  background-image: url("../../Assets/Background Login Sett Solutions.jpg");
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerBackgroundLogin .containerLogin {
  background-image: linear-gradient(-360deg, rgba(14, 31, 66, 0.7) 20%, rgba(28, 78, 145, 0.7) 100%);
  width: 340px;
  /* height: 65vh; */
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  gap: 20px;
}

.containerBackgroundLogin .containerInputs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.containerBackgroundLogin .containerInputs .inputLabelLogin,
.containerBackgroundLogin .buttonLogin,
.containerBackgroundLogin .inputLogin {
  width: 100%;
  height: 40px;
  border-radius: 25px;
  border: none;
}

.containerBackgroundLogin .errorINputRegi {
  margin-top: 8px;
}

.containerBackgroundLogin .buttonLogin {
  background-color: #00ff66;
  color: #0a554a;
  font-size: 25px;
  cursor: pointer;
  margin-top: 10px;
}

.containerBackgroundLogin .containerLoginForgot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.containerBackgroundLogin .containerLines {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.containerBackgroundLogin .containerLines .line {
  width: 30%;
  height: 1px;
  background-color: #ffffff;  
}

.containerBackgroundLogin .containerLines .text {
  color: #ffffff;
  font-size: 10px;
}

.containerBackgroundLogin .google-button{
  width: 100%;
  height: 40px;
  border-radius: 25px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4285f6;
  color: #ffffff;
  font-weight: 700;
  cursor: pointer;
  gap: 10px;
}

.containerBackgroundLogin .google-logo {
  width: 30px;
}

.containerBackgroundLogin .logoSett{
  width: 180px;
}

.containerBackgroundLogin .containerSelectScreenLogin {
  width: 60%;
  height: 40px;
  border-radius: 25px;
  border: none;
  display: flex;
  background-color: #ffffff;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  flex: 1;
  margin-top: 30px;
  margin-bottom: 10px;
}

.containerBackgroundLogin .containerSelectScreenLogin div {
  flex: 1;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #0e1f42;
}


.containerBackgroundLogin .containerSelectScreenLogin .selected {
  background-color: #75cbee;
  border-radius: 25px;
}

.containerBackgroundLogin .buttonTerm {
  font-size: 8px;
  background-color: transparent;
  border: none;
  color: #ffffff;
  white-space: normal; /* Permite que o texto quebre em várias linhas */
  text-align: center; /* Centraliza o texto */
  display: block; /* Garante que o texto ocupe a largura total do botão */
}

.containerBackgroundLogin .containerTermsRegister {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.containerBackgroundLogin .buttonRegister {
  background-color:#75cbee;
  height: 32px;
  border-radius: 25px;
  border: none;
  padding: 0px 20px;
  color: #27486b;
}