@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

.containerCardPhoto {
  display: flex;
  flex-direction: column;
  background-color: #FDFDFD;
  width: 230px;
  padding: 16px;
  border-radius: 5px;
  margin-left: 20px;
}

.containerCardPhoto button {
  background-color: #B00020;
  border: none;
  cursor: pointer;
  border-radius: 30px;
  height: 35px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  color: #FDFDFD;
  margin-top: 10px;
}

.containerCardPhoto img {
  width: 230px;
  height: 100px;
  object-fit: cover;
}