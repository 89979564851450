.containerListItens {
  color: red;
}

.containerSideBar {
  /* position: absolute;
  left: 0;
  bottom: 0;
  top: 0; */
  background-color: #0E1F41;
  width: 272px ;
  height: 100vh;
}

.marginLeftSideBar {
  margin-left: 30px;
}

.menuSlected:hover {
  color: red;
}