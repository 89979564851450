.conatainerButtons {
  display: flex;
  max-width: 721px;
}

.buttonReviews {
  flex: 1;
  background-color: #FDFDFD;
  height: 48px;
  border: none;
  font-size: 10px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  color: #0E1F41;
}

.buttonReviewsSelected {
  border-bottom: 2px solid #0E1F41;
}

.containerAllCards {
  height: 55vh;
  /* position: fixed; */
  overflow: scroll;
  display: flex;
  max-width: 721px;
  flex-direction: column;
}

.containerAllCards::-webkit-scrollbar {
  display: none;
}
