@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

.divider {
  border: 1px solid #e5ebf2;
}

.containerAllCRedCard {
  height: 75vh;
  overflow: scroll;
}

.containerAllCRedCard::-webkit-scrollbar {
  display: none;
}

.containerCredCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.containerCredCard .buttonMakeMain {
  background-color: transparent;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  border-radius: 30px;
  border: 2px solid #1D4E92;
  padding: 4px 16px 4px 16px;
  gap: 10px;
  color: #1D4E92;
  cursor: pointer;
}

.containerCredCard .buttonMain {
  background-color: #1D4E92;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  border-radius: 30px;
  padding: 4px 16px 4px 16px;
  gap: 10px;
  color: #FFFFFF;
  border: none;
}

.containerCredCard .containerInfoCards {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #1D4E92;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
}

.buttonCredCard {
  background-color: #7CC8E4;
  width: 100%;
  height: 40px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 40px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;
}

.containerButonsCredCard {
  display: flex;
}

.containerAllCRedCard .deleteCleaningButton {
  background-color: #ff0000;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  border-radius: 30px;
  padding: 4px 16px 4px 16px;
  gap: 10px;
  color: #FFFFFF;
  border: none;
  margin-right: 8px;
}
