@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');


.containerBackgroundLoginGoogleMobile {
  background-image: url("../../Assets/Background Login Sett Solutions.jpg");
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerBackgroundLoginGoogleMobile .containerLoginGoogleMobile {
  background-image: url("../../Assets/LoginBackground.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 85vw;
  height: 90vh;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
}

.containerBackgroundLoginGoogleMobile .containerInputs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.containerBackgroundLoginGoogleMobile .containerInputs .inputLabelLoginGoogleMobile,
.containerBackgroundLoginGoogleMobile .buttonLoginGoogleMobile,
.containerBackgroundLoginGoogleMobile .inputLoginGoogleMobile {
  width: 100%;
  height: 40px;
  border-radius: 25px;
  border: none;
}

.containerBackgroundLoginGoogleMobile .errorINputRegi {
  margin-top: 8px;
}

.containerBackgroundLoginGoogleMobile .buttonLoginGoogleMobile {
  background-color: #00ff66;
  color: #0a554a;
  font-size: 25px;
  cursor: pointer;
  margin-top: 10px;
}

.containerBackgroundLoginGoogleMobile .containerLines .text {
  color: #ffffff;
  font-size: 10px;
}

.containerBackgroundLoginGoogleMobile .google-button{
  width: 90%;
  height: 40px;
  border-radius: 25px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4285f6;
  color: #ffffff;
  font-weight: 700;
  cursor: pointer;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.containerBackgroundLoginGoogleMobile .return-button{
  width: 90%;
  height: 40px;
  border-radius: 25px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00ff66;
  color: #0a554a ;
  font-weight: 700;
  cursor: pointer;
  gap: 10px;
  margin-top: 10px;
  font-size: 15px;
}

.containerBackgroundLoginGoogleMobile .google-logo {
  width: 30px;
}

.containerBackgroundLoginGoogleMobile .logoSett{
  width: 200px;
  align-self: flex-start;
  margin-left: 20px;
}

.containerBackgroundLoginGoogleMobile .containerLines {
  display: flex;
  width: 90%;
  justify-content: space-around;
  align-items: center;
}

.containerBackgroundLoginGoogleMobile .containerLines .line {
  width: 30%;
  height: 1px;
  background-color: #ffffff;  
}

.containerBackgroundLoginGoogleMobile .containerLines .text {
  color: #ffffff;
  font-size: 10px;
}