.calendar {
  font-family: Arial, sans-serif;
  width: 40vw;
  background-color: #fafafa;
  padding: 20px;
  height: 100%;
}

.selectCalendar {
  padding: 10px;
  border-radius: 5px;
}

.header {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  padding: 25px;
  gap: 10px;
}

.weekdays {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 5px 0;

}

.calendar .random {
  background-color: #cdd3dc;
  color: black;
}

.weekday {
  font-weight: bold;
  flex: 1;
  text-align: center;
  color: #bcc3ce;
  font-size: 10px;
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.day {
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

.selected {
  background-color: #86c71c !important;
  color: #fafafa;
}

.today {
  background-color: #86c71c;
  color: #fafafa;
}