@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

.containerButtonDelete {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.containerButtonDelete .delete {
  background-color: #b00020;
  border: none;
  color: #FFFFFF;
  margin-left: 5px;
  padding: 10px;
  font-size: 12px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
}

.containerButtonDelete .close {
  background-color: #1d4e92;
  border: none;
  color: #FFFFFF;
  margin-left: 5px;
  padding: 10px;
  font-size: 12px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
}